import Config from "@kaspernj/api-maker/build/config"
import I18nOnSteroids from "i18n-on-steroids"

const apiMakerFiles = require.context("../../../config/locales/api_maker", true, /^.\/([a-z.]+)\.json$/)
const dateTimeFiles = require.context("../../../config/locales/awesome_translations/date_time", true, /^(.+)\.json$/)
const hayaSelectFiles = require.context("../../../config/locales/haya_select", true, /^(.+)\.json$/)
const i18n = new I18nOnSteroids({
  fallbacks: {
    da: ["da", "en"],
    en: ["en", "da"]
  }
})
const jsFiles = require.context("../../../config/locales/js", true, /^(.+)\.json$/)
const awesomeTranslationsJsFiles = require.context("../../../config/locales/awesome_translations/js", true, /^(.+)\.json$/)
const modelFiles = require.context("../../../config/locales/awesome_translations/models", true, /^(.+)\.json$/)
const numbersFiles = require.context("../../../config/locales/awesome_translations/numbers", true, /^(.+)\.json$/)
const routetTranslatorFiles = require.context("../../../config/locales/route_translator", true, /^(.+)\.json$/)

i18n.scanRequireContext(apiMakerFiles)
i18n.scanRequireContext(awesomeTranslationsJsFiles)
i18n.scanRequireContext(dateTimeFiles)
i18n.scanRequireContext(hayaSelectFiles)
i18n.scanRequireContext(jsFiles)
i18n.scanRequireContext(modelFiles)
i18n.scanRequireContext(numbersFiles)
i18n.scanRequireContext(routetTranslatorFiles)

const htmlLang = document.querySelector("html").getAttribute("lang")
let locale

if (htmlLang) {
  const htmlLangMatch = htmlLang.match(/^([A-z]{2})(-|$)/)

  if (htmlLangMatch) {
    locale = htmlLangMatch[1]
  }
}

if (!locale) locale = "da"

I18nOnSteroids.setCurrent(i18n)

i18n.setLocale(locale)
i18n.setLocaleOnStrftime()

Config.setI18n(i18n)

export default i18n
