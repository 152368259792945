import {Shape, shouldComponentUpdate} from "set-state-compare"
import fetchingObject from "fetching-object"
import I18n from "shared/i18n"
import React from "react"

export default class BaseComponent extends React.Component {
  originalComponentDidMount = this.componentDidMount
  originalRender = this.render
  p = fetchingObject(() => this.props)
  s = fetchingObject(() => this.shape || this.state)
  t = fetchingObject(this)

  render = () => this.originalRender()

  componentDidMount = () => {
    if (!this.shape) this.shape = new Shape(this, {})

    this.shape.currentLocale = I18n.locale

    if (this.originalComponentDidMount) this.originalComponentDidMount()
  }

  onLocaleChanged = () => {
    this.shape.set({
      currentLocale: I18n.locale
    })
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shouldComponentUpdate(this, nextProps, nextState)
  }
}
