import BaseComponent from "components/base-component"
import classNames from "classnames"
import DynamicOverflow from "components/dynamic-overflow"
import PropTypes from "prop-types"
import React from "react"
// import "./style"

export default class ComponentsCard extends BaseComponent {
  static defaultProps = {
    scrollable: false
  }

  static propTypes = {
    cardRef: PropTypes.any,
    children: PropTypes.node,
    className: PropTypes.string,
    footer: PropTypes.node,
    header: PropTypes.node,
    scrollable: PropTypes.bool.isRequired
  }

  render() {
    const {cardRef, children, className, footer, header, scrollable, ...restProps} = this.props

    return (
      <div
        className={classNames("components--card", className)}
        data-has-footer={Boolean(footer)}
        data-scrollable={scrollable}
        ref={cardRef}
        {...restProps}
      >
        <div className="card-inner">
          {scrollable &&
            <DynamicOverflow>
              {this.cardContent()}
            </DynamicOverflow>
          }
          {!scrollable && this.cardContent()}
        </div>
        {footer &&
          <div className="card-footer">
            {footer}
          </div>
        }
      </div>
    )
  }

  cardContent() {
    const {children, header} = this.props

    return (
      <>
        {header &&
          <div className="card-header">
            {header}
          </div>
        }
        <div className="card-body">
          {children}
        </div>
      </>
    )
  }
}
