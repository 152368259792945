import {digg} from "diggerize"
const localeToCountryCode = {
  "da": "Danish",
  "en": "English"
}

export default class Locales {
  static availableLocales() {
    return ["da", "en"]
  }

  static labelForLocale(locale) {
    return digg(localeToCountryCode, locale)
  }
}
