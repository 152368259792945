import {PortalHost, PortalProvider} from "conjointment"
import ApiMakerConfig from "@kaspernj/api-maker/build/config"
import AppHistory from "@kaspernj/api-maker/build/history"
import {createRoot} from "react-dom/client"
import {LoadingBlocker} from "components/layout/loading-blocker"
import ModelsResponseReader from "@kaspernj/api-maker/build/models-response-reader"
import {Container as Notifications} from "flash-notifications"
import React from "react"
import Router from "@kaspernj/api-maker/build/router"
import Routes from "shared/routes"
import SessionStatusUpdater from "@kaspernj/api-maker/build/session-status-updater"
import {WithApiMaker} from "@kaspernj/api-maker/build/with-api-maker"
import {WithDefaultStyle} from "@kaspernj/api-maker/build/utils/default-style"
import WithLocationPath from "on-location-changed/build/with-location-path"

require("shared/devise")

if (window.currentUserData) {
  const modelsResponseReader = new ModelsResponseReader({response: window.currentUserData})
  const models = modelsResponseReader.models()

  window.currentUser = models[0]
}

SessionStatusUpdater.current().startTimeout()

const LayoutNotFoundPage = React.lazy(() => import(/* webpackChunkName: "not-found-page" */ "components/layout/not-found-page"))
const requireComponent = ({routeDefinition}) => React.lazy(() => import(/* webpackChunkName: "[request]" */ `routes/${routeDefinition.component}-route`))
const routeDefinitions = require("shared/route-definitions.json")

ApiMakerConfig.setHistory(AppHistory)
ApiMakerConfig.setRoutes(Routes)
ApiMakerConfig.setRouteDefinitions(routeDefinitions)

const defaultStyle = {
  Text: {
    fontFamily: "barlow, sans-serif",
    fontSize: 17
  }
}

document.addEventListener("DOMContentLoaded", () => {
  const reactRoot = document.querySelector(".react-root")
  const root = createRoot(reactRoot)

  root.render(
    <WithApiMaker config={ApiMakerConfig}>
      <WithLocationPath>
        <PortalProvider>
          <PortalHost name="base" placement="below">
            <WithDefaultStyle style={defaultStyle}>
              <LoadingBlocker />
              <Notifications />
              <Router
                history={AppHistory}
                notFoundComponent={LayoutNotFoundPage}
                requireComponent={requireComponent}
                routeDefinitions={routeDefinitions}
                routes={Routes}
              />
            </WithDefaultStyle>
          </PortalHost>
        </PortalProvider>
      </WithLocationPath>
    </WithApiMaker>
  )
})
