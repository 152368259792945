import React, {useCallback} from "react"
import EventEmitter from "events"
import LoadingSpinner from "components/layout/loading-spinner"
import memo from "set-state-compare/src/memo"
import Overlay from "components/layout/overlay"
import useEventEmitter from "@kaspernj/api-maker/build/use-event-emitter"
import useShape from "set-state-compare/src/use-shape"
import UtilsText from "@kaspernj/api-maker/build/utils/text"
import {v4 as uuidv4} from "uuid"
import {View} from "react-native"

const state = {count: 0}
const eventEmitter = new EventEmitter()

const increaseLoading = (loadingId, label) => {
  state.count += 1
  eventEmitter.emit("loadingIncreased", loadingId, label)
}

const decreaseLoading = (loadingId) => {
  state.count -= 1
  eventEmitter.emit("loadingDecreased", loadingId)
}

const loading = async (label, callback) => {
  const loadingId = uuidv4()

  try {
    increaseLoading(loadingId, label)

    return await callback()
  } finally {
    decreaseLoading(loadingId)
  }
}

const LoadingBlocker = memo(() => {
  const s = useShape()

  s.useStates({loadings: {}})

  const onLoadingDecreased = useCallback((loadingId) => {
    const newLoadings = Object.assign({}, s.s.loadings)
    const oldLength = Object.keys(newLoadings).length

    if (!(loadingId in newLoadings)) {
      throw new Error(`Loading ID ${loadingId} wasn't found in loadings: ${Object.keys(s.s.loadings).join(", ")}`)
    }

    delete newLoadings[loadingId]

    s.set({loadings: newLoadings})

    const newLength = Object.keys(s.s.loadings).length

    if (newLength != (oldLength - 1)) {
      throw new Error("Loadings didnt decrease!")
    }
  }, [])

  const onLoadingIncreased = useCallback((loadingId, label) => {
    const newLoadings = Object.assign({}, s.s.loadings)

    newLoadings[loadingId] = {id: loadingId, label}

    s.set({loadings: newLoadings})
  }, [])

  const count = Object.keys(s.s.loadings).length

  useEventEmitter(eventEmitter, "loadingDecreased", onLoadingDecreased)
  useEventEmitter(eventEmitter, "loadingIncreased", onLoadingIncreased)

  if (count <= 0) return null

  return (
    <Overlay className="components--layout--loading-blocker">
      <View style={{display: "flex", alignItems: "center"}}>
        <View style={{marginRight: "25px"}}>
          <LoadingSpinner />
        </View>
        <View>
          {Object.values(s.s.loadings).map((loading) =>
            <View key={loading.id}>
              <UtilsText style={{color: "#fff"}}>{loading.label}</UtilsText>
            </View>
          )}
        </View>
      </View>
    </Overlay>
  )
})

export {decreaseLoading, increaseLoading, loading, LoadingBlocker}
